import { FormikHelpers } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactElement, useState } from "react";
import * as Yup from "yup";

import PageStepAnimation from "../../../../../../atoms/page-step-animation/PageStepAnimation";
import { Image } from "../../../../../../core/utils/images";
import PageStepForm from "../../../../../../organisms/page-step-form/PageStepForm";
import {
  useResult,
  useUpdateResult,
} from "../../../../../../organisms/result/use-cases/result-use-cases";
import { Events, track } from "../../../../../../utils/analytics";
import { handleUpdateResultErrors } from "../../../../../../utils/error-utils/catch-error-handlers";
import useTrackPageViewed from "../../../../../../utils/hooks/useTrackPageViewed";
import { maxCardholderNameLength } from "../../../../settings/barkibu_card";
import AlternativeCardholderNameForm from "../../../organisms/cardholder-name-form/AlternativeCardholderNameForm";
import * as styles from "./AlternativeCardholderName.module.scss";

interface AlternativeCardholderNameFormValues {
  cardholderName: string;
}

const cardPigSVG: Image = require("../../../images/pages/cardholder-name/card-pig.svg") as Image;

const cardPigSVGSrc: string = cardPigSVG.default;

const AlternativeCardholderName = (): ReactElement => {
  const { t } = useTranslation();
  const result = useResult();
  const updateResult = useUpdateResult();
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

  const nextUrl = `/results/${result?.uuid}/card/address/`;
  const initialCardHolderName = (): string => {
    if (!result) {
      return "";
    }
    const firstName = result.pet_parent_first_name || "";
    const lastName = result.pet_parent_last_name || "";
    const resultFullName = `${firstName} ${lastName}`;

    return (
      result.barkibu_card_cardholder_name ?? resultFullName.substring(0, maxCardholderNameLength)
    );
  };

  useTrackPageViewed(Events.CARDHOLDER_NAME_VIEWED_BROWSER);

  const cardholderNameFormData = {
    initialValues: {
      cardholderName: initialCardHolderName(),
    },
    validationSchema: Yup.object({
      cardholderName: Yup.string()
        .trim()
        .required(t("common.validation.required"))
        .matches(/^[a-zA-ZÀ-ÿ\s'-]+$/, t("pg_cardholder_name.validation.invalid_characters")),
    }),
    handleSubmit: async (
      values: AlternativeCardholderNameFormValues,
      { setSubmitting }: FormikHelpers<AlternativeCardholderNameFormValues>
    ) => {
      if (!updateResult) {
        return;
      }

      setSubmitting(true);
      track(Events.FORM_ANSWERED, {
        eventSender: "Cardholder Name",
        initialName: initialCardHolderName(),
        newName: values.cardholderName,
        nameChanged: initialCardHolderName() !== values.cardholderName,
      });

      try {
        await updateResult({ barkibu_card_cardholder_name: values.cardholderName });
        void navigate(nextUrl);
      } catch (error) {
        handleUpdateResultErrors(error, "cardholder name");
        setHasSubmitError(true);
      } finally {
        setSubmitting(false);
      }
    },
    children: () => {
      return <AlternativeCardholderNameForm hasSubmitError={hasSubmitError} />;
    },
  };

  return (
    <>
      {result && (
        <PageStepAnimation adoptionClassName={styles.cardholderName}>
          <PageStepForm
            title={t("pg_alternate_cardholder_name.title")}
            image={cardPigSVGSrc}
            subtitle={t("pg_alternate_cardholder_name.subtitle")}
            formData={cardholderNameFormData}
          />
        </PageStepAnimation>
      )}
    </>
  );
};

export default AlternativeCardholderName;
