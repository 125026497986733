import { graphql } from "gatsby";
import { ReactElement } from "react";

import withFunnelWrapper from "../../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import AlternativeCardholderName from "../../../../features/quotes-funnel/ui/pages/card/cardholder-name/AlternativeCardholderName";
import CardholderName from "../../../../features/quotes-funnel/ui/pages/card/cardholder-name/CardholderName";
import { useResult } from "../../../../organisms/result/use-cases/result-use-cases";
import { CountryCode } from "../../../../settings/countries";
import { PageId } from "../../../../settings/pages";
import { experiments, getExperimentVariant, VARIANTB } from "../../../../utils/experiments";

const Page = (): ReactElement => {
  const result = useResult();

  const getCardHolderNameComponent = (): ReactElement => {
    if (result?.country !== CountryCode.ES) {
      return <AlternativeCardholderName />;
    }

    return getExperimentVariant(experiments.barkibuCard) === VARIANTB ? (
      <AlternativeCardholderName />
    ) : (
      <CardholderName />
    );
  };

  return getCardHolderNameComponent();
};

export default withFunnelWrapper(Page, "pg-cardholder-name", PageId.cardholderName);

export const query = graphql`
  query CardholderNamePageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
